import { qs, qsa, $on, $delegate } from './utils';
import TweenMax from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import "./lets-create-something-amazing";
import '../stylesheets/style.scss';

class NavigationMenu {
  constructor() {
    let docWindow = qs('html');
    let navButton = qs('.osg-menu');
    let navMenu = qs('.osg-navigation');
    let navMenuExit = qs('.osg-navigation--exit');
    let controller = new ScrollMagic.Controller();
    
    let myScene2 = new ScrollMagic.Scene({
      triggerElement: '.osg-services--panel',
      reverse: false,
      triggerHook: .9,
    })
    .setClassToggle('.osg-services--panel', 'fade-in')
    .addTo(controller);


    if(navButton) {
      navButton.addEventListener('click', (e) => {
        docWindow.style.overflow = 'hidden';
        navMenu.setAttribute('data-expanded', 'true');
        TweenMax.to('.osg-navigation', .15, {opacity: 1, ease: Power1.easeIn});
        TweenMax.to('.osg-navigation--external', .5, {right:0, opacity: 1, delay: .25, ease: Power1.easeOut});
      });
    }
    
    if(navMenuExit) {
      navMenuExit.addEventListener('click', (e) => {
        TweenMax.to('.osg-navigation', .15, {opacity: 0, ease: Power1.easeIn});
        TweenMax.to('.osg-navigation--external', .5, {right:'-10%', opacity: 0, ease: Power1.easeIn, onComplete: () => {
          docWindow.style.overflow = 'auto';
          navMenu.setAttribute('data-expanded', 'false');
        }});
      });
    }
  }
}

new NavigationMenu();