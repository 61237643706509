import { qs, qsa, $on, $delegate } from './utils';
import TweenMax from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";

class SSMOverlay {
  constructor() {
    this.ssmOverlayTrigger = qs('.osg--action.seo');
    if(this.ssmOverlayTrigger) {
      this.initSSMModal(this.ssmOverlayTrigger);
    }
  }

  initSSMModal(trigger) {
    const exit = qs('.osg-overlay--exit');
    exit.addEventListener('click', (e) => {
      e.preventDefault();
      const overlay = qs('.osg-overlay');
      overlay.setAttribute('aria-hidden', 'true');
      document.body.style.overflow = "auto";
      
      setTimeout(() => {
        overlay.style.display = "none";
      }, 20);
    });

    trigger.addEventListener('click', (evt) => {
      evt.preventDefault();
      const overlay = qs('.osg-overlay');
      overlay.style.top = window.scrollY+'px';
      overlay.style.display = "block";
      document.body.style.overflow = "hidden";

      setTimeout(() => {
        overlay.setAttribute('aria-hidden', 'false');
      }, 20);
    });
  }
}

new SSMOverlay();